import React, { useEffect, useCallback, useId } from 'react'
import { createPortal } from 'react-dom'

import { ModalLayout, ModalTrigger, useModalContext } from '.'

import { useSlots, useControllableState, useEscapeKey } from 'lib/hooks'

import { bool } from 'prop-types'

const Portal = ({ children }) => {
  return createPortal(children, document.getElementById('modal-container'))
}

export const Modal = ({
  children,
  active,
  onChangeActive,
  scrollable,
  preventCloseOnEscapeInsideTagName = [],
}) => {
  const name = useId()

  const [isActive, setIsActive] = useControllableState(active || false, active, onChangeActive)
  const containerAvailable = document.getElementById('modal-container') !== null

  const {
    state: { activeModal, closeModal },
    dispatch,
  } = useModalContext()

  const [trigger] = useSlots(['trigger'], children)

  const escapeKeyCallback = useCallback(
    (evt) => {
      if (
        activeModal === name &&
        !preventCloseOnEscapeInsideTagName
          .map((tagName) => tagName.toUpperCase())
          .includes(evt.target.tagName)
      ) {
        setIsActive(false)
      }
    },
    [preventCloseOnEscapeInsideTagName, activeModal, name, setIsActive]
  )

  useEscapeKey(escapeKeyCallback)

  useEffect(() => {
    if (name === activeModal) {
      setIsActive(true)
    }
  }, [name, activeModal, setIsActive])

  useEffect(() => {
    if (name === closeModal) {
      setIsActive(false)
    }
  }, [name, closeModal, setIsActive])

  useEffect(() => {
    dispatch({ type: 'setActiveModal', payload: { name, isActive } })
  }, [dispatch, name, isActive])

  useEffect(() => {
    // if modal is closed by simply not rendering anymore, the context needs to know about that:
    return () => {
      dispatch({ type: 'setActiveModal', payload: { name, isActive: false } })
    }
  }, [dispatch, name])

  const onOpen = useCallback(() => {
    dispatch({ type: 'setActiveModal', payload: { name, isActive: true } })
  }, [dispatch, name])

  const onClose = useCallback(() => {
    setIsActive(false)
  }, [setIsActive])

  return (
    <>
      {trigger && (
        <ModalTrigger for={name} onOpenModal={onOpen} {...trigger.props}>
          {trigger.props.children}
        </ModalTrigger>
      )}
      {isActive && containerAvailable ? (
        <Portal>
          <ModalLayout name={name} scrollable={scrollable} onClickClose={onClose}>
            {children}
          </ModalLayout>
        </Portal>
      ) : null}
    </>
  )
}

Modal.displayName = 'modal'

Modal.Footer = ({ children }) => children
Modal.Footer.displayName = 'footer'

Modal.Headline = ({ children }) => children
Modal.Headline.displayName = 'headline'

Modal.Section = ({ children }) => children
Modal.Section.displayName = 'sections'

Modal.SubHeadline = ({ children }) => children
Modal.SubHeadline.displayName = 'subHeadline'

Modal.Title = ({ children }) => children
Modal.Title.displayName = 'title'

Modal.Trigger = ({ children }) => children
Modal.Trigger.displayName = 'trigger'

Modal.View = ({ children }) => children
Modal.View.displayName = 'views'
Modal.propTypes = {
  active: bool,
}
