import { variant } from 'styled-system'
import { getThemeValue } from 'theming'

export const appearance = (props) => {
  return variant({
    prop: 'appearance',
    variants: {
      lightgray: {
        backgroundColor: getThemeValue(props.theme, 'uiActionElements.lightgray.default.bg'),
        border: '1px solid',
        borderColor: getThemeValue(props.theme, 'uiActionElements.lightgray.default.bg'),
        borderRadius: getThemeValue(props.theme, 'uiActionElements.radius.default'),
        color: getThemeValue(props.theme, 'colors.base.gray.230'),

        '&::placeholder ': {
          color: getThemeValue(props.theme, 'uiActionElements.lightgray.placeholder.color'),
        },
        '&:hover ': {
          backgroundColor: getThemeValue(props.theme, 'uiActionElements.lightgray.hover.bg'),
          borderColor: getThemeValue(props.theme, 'uiActionElements.lightgray.hover.bg'),
          color: getThemeValue(props.theme, 'uiActionElements.lightgray.hover.color'),
        },
        '&:disabled ': {
          backgroundColor: getThemeValue(props.theme, 'uiActionElements.lightgray.disabled.bg'),
          borderColor: getThemeValue(props.theme, 'uiActionElements.lightgray.disabled.bg'),
          color: getThemeValue(props.theme, 'uiActionElements.lightgray.disabled.color'),
        },
        ...(props.checked && {
          backgroundColor: getThemeValue(props.theme, 'uiActionElements.lightgray.checked.bg'),
          borderColor: getThemeValue(props.theme, 'uiActionElements.lightgray.checked.bg'),
          color: getThemeValue(props.theme, 'uiActionElements.lightgray.checked.color'),
        }),
      },
    },
  })
}
