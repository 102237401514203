import { useEffect } from 'react'

const ESCAPE_KEY = 'Escape'

export const useEscapeKey = (callback) => {
  useEffect(() => {
    if (!window || !window.document || !callback) return

    const onKeyPress = (event) => {
      event.key === ESCAPE_KEY && callback(event)
    }
    // third parameter on addEventListener is true to useCapture instead of bubbling,
    // so last added listener will be handled first.
    // that allows usage of stopPropagation to prevent "parent" elements from closing
    // on escape (e.g. subpermission modal on users licence edit closes UserEdit component
    // as well, if useCapture not true)
    window.document.addEventListener('keydown', onKeyPress, true)
    return () => {
      window.document.removeEventListener('keydown', onKeyPress, true)
    }
  }, [callback])
}
