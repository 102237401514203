import createStore from 'lib/flux-store'
import api from 'stores/api'

const initialState = {
  isLoading: true,
  groupsForFilter: [],
  locationStats: [],
  navigationStats: {},
  exportStats: {},
  tableColumnsAllFilter: [],
  gacAllFilter: [],
}

const actions = {
  fetchLocationStats:
    ({ filter }) =>
    (dispatch) => {
      dispatch({ type: 'setLoading', payload: true })
      api.AppStats.getLocationStats({ ...filter })
        .then((result) => {
          if (result.data.status && result.data.status === 'success') {
            dispatch({ type: 'setLocationStats', payload: { data: result.data.data } })
          } else {
            dispatch({ type: 'setFailure', payload: result.data.messages })
          }
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
        })
    },
  fetchNavigationStats:
    ({ filter }) =>
    (dispatch) => {
      dispatch({ type: 'setLoading', payload: true })
      api.AppStats.getNavigationStats({ ...filter })
        .then((result) => {
          if (result.data.status && result.data.status === 'success') {
            dispatch({ type: 'setNavigationStats', payload: { data: result.data.data } })
          } else {
            dispatch({ type: 'setFailure', payload: result.data.messages })
          }
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
        })
    },
  fetchExportStats:
    ({ filter }) =>
    (dispatch) => {
      dispatch({ type: 'setLoading', payload: true })
      api.AppStats.getExportStats({ ...filter })
        .then((result) => {
          if (result.data.status && result.data.status === 'success') {
            dispatch({ type: 'setExportStats', payload: { data: result.data.data } })
          } else {
            dispatch({ type: 'setFailure', payload: result.data.messages })
          }
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
        })
    },
  loadGroupsForFilter: () => (dispatch) => {
    dispatch({ type: 'setLoading', payload: true })
    api.Admin.getGroupList({ params: {} })
      .then((result) => {
        if (result.data.status && result.data.status === 'success') {
          dispatch({ type: 'setGroupsForFilter', payload: { data: result.data.data } })
        } else {
          dispatch({ type: 'setFailure', payload: result.data.messages })
        }
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
      })
  },
  setTableColumnsAllFilter: (columns) => ({ columns }),
  setGacAllFilter: (gacs) => ({ gacs }),
}

const reducer = {
  setLoading: (state, { payload }) => {
    return { ...state, payload, isFailure: false }
  },
  setFailure: (state, { payload }) => {
    return {
      ...state,
      dataIsLoading: false,
      isFailure: true,
      messages: payload,
    }
  },

  setLocationStats: (state, { payload }) => {
    return {
      ...state,
      isLoading: false,
      locationStats: payload.data,
    }
  },
  setNavigationStats: (state, { payload }) => {
    return {
      ...state,
      isLoading: false,
      navigationStats: payload.data,
    }
  },
  setExportStats: (state, { payload }) => {
    return {
      ...state,
      isLoading: false,
      exportStats: payload.data,
    }
  },
  setGroupsForFilter: (state, { payload }) => {
    const groups = payload?.data?.map((group) => ({ label: group.name, value: group.id }))
    return {
      ...state,
      isLoading: false,
      groupsForFilter: groups,
    }
  },
  setTableColumnsAllFilter: (state, { columns }) => ({
    ...state,
    tableColumnsAllFilter: columns,
  }),
  setGacAllFilter: (state, { gacs }) => ({
    ...state,
    gacAllFilter: gacs,
  }),
}

export const [StatisticsDataStoreContext, StatisticsDataStoreProvider, useStatisticsDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'StatisticsDataStore'
)
