import { styled } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { FlyoutContainer } from './FlyoutContainer'

export const FlyoutContainerWithTriangle = styled(FlyoutContainer)`
  &::before {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${(props) => props.triangleSize + 'px'};
    border-color: transparent;
    z-index: 10;
  }

  &.triangle-top-start,
  &.triangle-bottom-start {
    &::before {
      left: ${(props) => props.triangleSize + 'px'};
    }
  }
  &.triangle-top-end,
  &.triangle-bottom-end {
    &::before {
      right: ${(props) => props.triangleSize + 'px'};
    }
  }
  &.triangle-left-start,
  &.triangle-right-start {
    &::before {
      top: ${(props) => props.triangleSize + 'px'};
    }
  }
  &.triangle-left-end,
  &.triangle-right-end {
    &::before {
      bottom: ${(props) => props.triangleSize + 'px'};
    }
  }

  &.triangle-top-start,
  &.triangle-top-end {
    &::before {
      bottom: ${(props) => `-${props.triangleSize * 2 - 1}px`};
      border-top-color: ${themeGet('flyout.bg', '#fff')};
    }
  }
  &.triangle-bottom-start,
  &.triangle-bottom-end {
    &::before {
      top: ${(props) => `-${props.triangleSize * 2 - 1}px`};
      border-bottom-color: ${themeGet('flyout.bg', '#fff')};
    }
  }
  &.triangle-left-start,
  &.triangle-left-end {
    &::before {
      right: ${(props) => `-${props.triangleSize * 2 - 1}px`};
      border-left-color: ${themeGet('flyout.bg', '#fff')};
    }
  }
  &.triangle-right-start,
  &.triangle-right-end {
    &::before {
      left: ${(props) => `-${props.triangleSize * 2 - 1}px`};
      border-right-color: ${themeGet('flyout.bg', '#fff')};
    }
  }
`
